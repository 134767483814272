<template>
  <b-card>
    <b-form method="post" @submit="updateSetting">
      <div class="mt-1">
        <h4>Tray Type will be</h4>

        <div class="demo-inline-spacing">
          <b-form-radio
            v-model="selected"
            :aria-describedby="ariaDescribedby"
            name="color"
            value="color"
            class="mr-2"
            >Color</b-form-radio
          >
          <b-form-radio
            v-model="selected"
            :aria-describedby="ariaDescribedby"
            name="number"
            value="number"
            >Number</b-form-radio
          >
        </div>
      </div>
      <div v-if="this.selected === 'color'">
        <div>
          <b-form
            ref="form"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <!-- Row Loop -->
            <h4 class="mt-3 mb-1">Color</h4>
            <b-row v-for="item in items" :id="item.id" :key="item.id" ref="row">
              <!-- Item Name -->
              <b-row>
                <b-col v-for="color in colorList" :key="color">
                  <b-form-input
                    name="color"
                    type="color"
                    :value="color"
                    style="width: 100px"
                    class="ml-2 color-item"
                  />
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-25 deleteIcon"
                    @click="() => deleteColor(color)"
                  />
                </b-col>
              </b-row>
            </b-row>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-form>
          <b-form-input
            type="color"
            id="my-color"
            style="opacity: 0; visibility: hidden; position: absolute"
            v-on:input="getInputColor"
            v-model="selectedColor"
            name="selectedColor"
          />
          <label for="my-color" class="AddNew">
            <feather-icon icon="PlusIcon" class="mr-25" />Add New</label
          >
        </div>
      </div>
      <div v-else></div>
      <div>
        <div
          class="
            demo-inline-spacing
            d-flex
            justify-content-end
            align-items-center
          "
        >
          <div class="mt-1">celsius</div>
          <b-form-checkbox
            v-model="checked"
            name="checked"
            value="F"
            unchecked-value="C"
            switch
          >
            fahrenheit
          </b-form-checkbox>
        </div>
        <h2 class="mb-2 mt-5">Setter</h2>
        <h4 class="mb-1 mt-2">Set Temperature</h4>
        <b-row>
          <b-col>
            <b-form-group label="Minimum " label-for="Minimum">
              <b-form-input
                name="min_temp_setter"
                v-model="hatcherySetting.min_temp_setter"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Maximum" label-for="Maximum">
              <b-form-input
                name="max_temp_setter"
                v-model="hatcherySetting.max_temp_setter"
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h4 class="mb-1 mt-2">Set Humidity</h4>
        <b-row>
          <b-col>
            <b-form-group label="Minimum " label-for="Minimum">
              <b-form-input
                name="min_humidity_setter"
                v-model="hatcherySetting.min_humidity_setter"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Maximum" label-for="Maximum">
              <b-form-input
                name="max_humidity_setter"
                v-model="hatcherySetting.max_humidity_setter"
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h2 class="mb-2 mt-2">Hatcher</h2>
        <h4 class="mb-1 mt-2">Set Temperature</h4>
        <b-row>
          <b-col>
            <b-form-group label="Minimum " label-for="Minimum">
              <b-form-input
                name="min_temp_hatcher"
                v-model="hatcherySetting.min_temp_hatcher"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Maximum" label-for="Maximum">
              <b-form-input
                name="max_temp_hatcher"
                v-model="hatcherySetting.max_temp_hatcher"
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h4 class="mb-1 mt-2">Set Humidity</h4>
        <b-row>
          <b-col>
            <b-form-group label="Minimum " label-for="Minimum">
              <b-form-input
                name="min_humidity_hatcher"
                v-model="hatcherySetting.min_humidity_hatcher"
                placeholder=""
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Maximum" label-for="Maximum">
              <b-form-input
                name="max_humidity_hatcher"
                v-model="hatcherySetting.max_humidity_hatcher"
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h2 class="mb-2 mt-2">Frequency</h2>
        <b-row class="mb-1">
          <b-col>
            <b-form-group label="Frequency" label-for="Frequency">
              <b-form-input
                name="frequency"
                v-model="hatcherySetting.frequency"
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="success"
        class="mr-1"
        value="Submit"
      >
        Submit
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios";
import { heightTransition } from "@core/mixins/ui/transition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      checked: "C",
      selected: null,
      color: null,
      colorList: [],
      hatcherySetting: {
        min_temp_setter: null,
        max_temp_setter: null,
        min_humidity_setter: null,
        max_humidity_setter: null,
        min_temp_hatcher: null,
        max_temp_hatcher: null,
        min_humidity_hatcher: null,
        max_humidity_hatcher: null,
        frequency: null,
        tray_type: null,
      },

      items: [
        {
          id: "",
        },
      ],
    };
  },
  mounted() {
    // this.initTrHeight();
  },
  created: function () {
    window.addEventListener("resize", this.initTrHeight);
    this.getSetting();
    // this.updateSetting();
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    getInputColor() {
      this.selectedColor;
      
      this.colorList.push(this.selectedColor);
    },
    deleteColor(color) {
      this.colorList = this.colorList.filter((item) => item !== color);
    },
    getSetting(id) {
      // const hatcher_id = this.$route.params.hatchId;
      const hatcheryId = this.$route.params.hatcheryId;
      axiosIns
        .get(`web/hatchery/${hatcheryId}/getSetting`)
        .then((response) => {
         
          this.hatcherySetting.min_temp_setter =
            response.data.hatcherySetting.min_temp_setter;

          this.hatcherySetting.max_temp_setter =
            response.data.hatcherySetting.max_temp_setter;

          this.hatcherySetting.min_temp_hatcher =
            response.data.hatcherySetting.min_temp_hatcher;

          this.hatcherySetting.max_temp_hatcher =
            response.data.hatcherySetting.max_temp_hatcher;

          this.hatcherySetting.min_humidity_setter =
            response.data.hatcherySetting.min_humidity_setter;

          this.hatcherySetting.max_humidity_setter =
            response.data.hatcherySetting.max_humidity_setter;

          this.hatcherySetting.min_humidity_hatcher =
            response.data.hatcherySetting.min_humidity_hatcher;

          this.hatcherySetting.max_humidity_hatcher =
            response.data.hatcherySetting.max_humidity_hatcher;

          this.hatcherySetting.frequency =
            response.data.hatcherySetting.frequency;
          // ( this.hatcherySetting.frequency,"frequcency")

          this.colorList = response.data.hatcherySetting.color_code;

          this.selected = response.data.hatcherySetting.tray_type;
          this.hatcherySetting.tray_type =
            response.data.hatcherySetting.tray_type;
          //  this.updateSetting();
        })
        .catch((error) => {
          error;
        });
    },
    updateSetting(e) {
      // ("update value")
      e.preventDefault();
      const hatcher_id = this.$route.params.hatchId;
      const hatcheryId = this.$route.params.hatcheryId;
      var data = new FormData();
      data.append("color_code", this.colorList);
      data.append("temp_type", this.checked);
      data.append("tray_type", this.hatcherySetting.tray_type);
      data.append("min_temp_setter", this.hatcherySetting.min_temp_setter);
      data.append("max_temp_setter", this.hatcherySetting.max_temp_setter);
      data.append(
        "min_humidity_setter",
        this.hatcherySetting.min_humidity_setter
      );
      data.append(
        "max_humidity_setter",
        this.hatcherySetting.max_humidity_setter
      );
      data.append("min_temp_hatcher", this.hatcherySetting.min_temp_hatcher);
      data.append("max_temp_hatcher", this.hatcherySetting.max_temp_hatcher);
      data.append(
        "min_humidity_hatcher",
        this.hatcherySetting.min_humidity_hatcher
      );
      data.append(
        "max_humidity_hatcher",
        this.hatcherySetting.max_humidity_hatcher
      );
      data.append("frequency", this.hatcherySetting.frequency);
      axiosIns
        .post(`web/hatchery/${hatcheryId}/setSetting`, data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "success",
              text: `Settings Updated Successfully `,
            },
          });
          // (response);
          // this.$router.push({
          //   path: `/apps/settings/hatchery-list`,
          // });
        })
        .catch((error) => {
          const data = error.response;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              text: data,
            },
          });
        });
    },
  },
};
</script>
<style scoped>
.AddNew {
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  background-color: #38c06c;
  color: #fff;
}
.color-item {
  position: relative;
}
.deleteIcon {
  position: absolute;
  top: -7px;
  right: 4px;
}
</style>
